import React, { FC } from "react";
import ReactSelect from "react-select";
import cn from "classnames";

type Option = {
	label: string;
	value: string;
};

type Props = {
	options: Option[];
	error?: boolean;
	errorText?: string;
	rootProps?: React.HTMLProps<HTMLDivElement>;
	inputProps?: React.ComponentProps<typeof ReactSelect>;
};

const Select: FC<Props> = (props) => {
	const { options, error = false, errorText = "", rootProps, inputProps } = props;

	return (
		<div {...rootProps}>
			<ReactSelect
				{...inputProps}
				unstyled
				options={options}
				classNames={{
					clearIndicator: () => "cursor-pointer mr-2",
					container: ({ isFocused }) =>
						cn("p-[1px] bg-gradient-to-t to-30% from-[#8F90A7] to-[#C2C3D0]", {
							"to-[#8F90A7]": isFocused,
						}),
					control: () => "cursor-pointer  p-5 border-none rounded-none bg-white",
					menu: () => "mt-1 border border-[#8F90A7] bg-white",
					menuList: () => "py-1",
					option: ({ isSelected }) =>
						cn("px-2 py-1 hover:bg-gray-100", { "bg-gray-200 hover:bg-gray-200": isSelected }),
					placeholder: () => "text-body md:text-body text-gray-02",
					multiValue: () => "mx-0.5 bg-gray-200",
					multiValueLabel: () => "px-1",
					multiValueRemove: () => "p-1",
				}}
			/>
			{error && (
				<p className="mt-2 text-[0.75rem] leading-[0.875rem] tracking-[-0.5%] text-[#E41D3E]">
					{errorText}
				</p>
			)}
		</div>
	);
};

export default Select;
