import { useEffect, useState } from "react";

import { IUseCookieBannerReturn } from "./useCookieBanner.types";
import { COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES } from "../../cookie-banner.constants";

const SCROLL_THRESHOLD = 300;

const useCookieBanner = (): IUseCookieBannerReturn => {
	const [isCookieBannerOpen, setIsCookieBannerOpen] = useState<boolean>(false);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isCookieBannerMinimized, setIsCookieBannerMinimized] = useState<boolean>(false);

	const handleOpenDialog = (): void => setIsDialogOpen(true);
	const handleCloseDialog = (): void => setIsDialogOpen(false);

	const handleDeny = (): void => {
		localStorage.setItem(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES.DENY);
		setIsCookieBannerOpen(false);
		setIsDialogOpen(false);
	};

	const handleAccept = (): void => {
		localStorage.setItem(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES.ACCEPT);
		setIsCookieBannerOpen(false);
		setIsDialogOpen(false);
	};

	const handleMaximize = (): void => {
		setIsCookieBannerMinimized(false);
	};

	useEffect(() => {
		const cookieConsent = localStorage.getItem(COOKIE_CONSENT_KEY);

		if (cookieConsent === null) setIsCookieBannerOpen(true);
	}, []);

	useEffect(() => {
		let lastScrollY = window.scrollY;

		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			if (currentScrollY > lastScrollY && currentScrollY > SCROLL_THRESHOLD) {
				setIsCookieBannerMinimized(true);
			}

			lastScrollY = currentScrollY;
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return {
		state: {
			isCookieBannerOpen,
			isDialogOpen,
			isCookieBannerMinimized,
		},
		handlers: {
			handleOpenDialog,
			handleCloseDialog,
			handleDeny,
			handleAccept,
			handleMaximize,
		},
	};
};

export default useCookieBanner;
