import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Banner, Dialog } from "./components";
import { useCookieBanner } from "./hooks";
import { ICookieBannerData } from "./cookie-banner.types";
import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";

const CookieBanner: FC = () => {
	const { allSanityCookieBanner } = useStaticQuery(graphql`
		{
			allSanityCookieBanner {
				nodes {
					language
					banner {
						icon {
							asset {
								localFile {
									publicURL
									extension
								}
								width
								height
							}
						}
						text
						openDialogLabel
						acceptLabel
					}
					dialog {
						logo {
							asset {
								localFile {
									publicURL
									extension
								}
								width
								height
							}
						}
						title
						subtitle
						link {
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
							}
						}
						denyLabel
						acceptLabel
					}
				}
			}
		}
	`) as ICookieBannerData;

	const cookieBanner = allSanityCookieBanner.nodes.find((node) => node.language === DEFAULT_LOCALE);

	if (!cookieBanner) return null;
	const { banner, dialog } = cookieBanner;

	const { state, handlers } = useCookieBanner();

	const { isCookieBannerOpen, isDialogOpen, isCookieBannerMinimized } = state;
	const { handleOpenDialog, handleCloseDialog, handleDeny, handleAccept, handleMaximize } =
		handlers;

	if (!isCookieBannerOpen) return null;

	return (
		<>
			<Banner
				content={banner}
				isVisible={!isDialogOpen}
				isMinimized={isCookieBannerMinimized}
				onOpenDialog={handleOpenDialog}
				onAccept={handleAccept}
				onMaximize={handleMaximize}
			/>
			<Dialog
				content={dialog}
				isOpen={isDialogOpen}
				onClose={handleCloseDialog}
				onAccept={handleAccept}
				onDeny={handleDeny}
			/>
		</>
	);
};

export default CookieBanner;
